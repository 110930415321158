import React, { useEffect, useState } from "react";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import Footer from "../../component/Footer";
import "../../assets/new/css/Forum.css";
import "../../assets/new/css/MyDiary.css";
import "../../assets/new/css/SchoolTvChannel.css";
import "../../assets/new/css/library.css";
import "../../assets/new/css/wallet.css";

import user1 from "../../assets/new/images/user/1.jpg";
import user2 from "../../assets/new/images/user/2.jpg";
import user3 from "../../assets/new/images/user/4.jpg";
import { simplePostCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Params from "../../config/Params";
import BreadCrumbs from "../../component/BreadCrumbs";
import SpinnerCmp from "../../component/SpinnerCmp";
import i18next from "i18next";

const AuthorizedPerson = () => {
  const listData = [
    {
      id: 1,
      image: user1,
      heading: "Parkshin",
      email: "parkshin@gmail.com",
      num: "9453442312",
    },
    {
      id: 2,
      image: user2,
      heading: "Deshana",
      email: "deshana@gmail.com",
      num: "9453442312",
    },
    {
      id: 3,
      image: user3,
      heading: "Harin",
      nav: "/AuthorizedPerson",
      email: "harin@gmail.com",
      num: "9453442312",
    },
  ];
  useEffect(() => {
    getDataList();
  }, []);
  const [personList, setPersonList] = useState([]);
  const [isProcessing, setisProcessing] = useState(true);
  const getDataList = () => {
    setisProcessing(true);
    let requestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      student_id: Params.student_id,
      language: i18next.language,
    });
    simplePostCall(ApiConfig.AUTHORIZED_PERSON, requestBody)
      .then((data) => {
        if (data.result == true) {
          setisProcessing(false);
          setPersonList(data.list);
        }
      })
      .catch((error) => {
        console.log(error, "api response");
      });
  };
  return (
    <>
      <div className="page-wrapper compact-wrapper" id="pageWrapper">
        <div className="page-body-wrapper sidebar-icon">
          <div className="page-body" id="container">
            <BreadCrumbs />
            <SpinnerCmp loading={isProcessing} />
            <div class="Forum library">
              <div className="main-section">
                <div className="row">
                  {personList?.map((item, index) => (
                    <div className="col-md-4 " key={index}>
                      <a href={item.nav}>
                        <div className="d-flex flex-row ps-4 pe-1 py-3 align-items-center my-1  shadow-sm cardFeed">
                          <div>
                            <img
                              src={
                                item?.gaurdian_image == ""
                                  ? user1
                                  : ApiConfig.NODE_URL +
                                    "/" +
                                    item?.gaurdian_image
                              }
                              alt=""
                              className="rounded-circle"
                              style={{ height: "58px", width: "58px" }}
                            />
                          </div>

                          <div className="d-flex flex-column  ps-3 ">
                            <small
                              // style={{
                              //   color: "#241549",
                              //   fontSize: "14px",
                              // }}
                              className="route-title "
                            >
                              {item?.gaurdian_first_name}
                            </small>
                            <small
                              // style={{
                              //   color: "#857E84",
                              //   fontSize: "12px",
                              // }}
                              className="route-title "
                            >
                              {item?.gaurdian_mobile}
                            </small>
                            <small
                              // style={{
                              //   color: "#857E84",
                              //   fontSize: "12px",
                              // }}
                              className="route-title "
                            >
                              {item?.gaurdian_email}
                            </small>
                          </div>
                        </div>
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default AuthorizedPerson;
