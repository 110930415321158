import { t } from "i18next";
import React from "react";
import { Link, useLocation } from "react-router-dom";

const BreadCrumbs = () => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  // console.log(pathnames, "yguyyugku");
  let breadcrumbPath = "";
  return (
    <div
      style={{
        marginBottom: "20px",
        fontSize: "16px",
        textTransform: "capitalize",
      }}
    >
      <Link to="/student/dashboard" style={{ color: "#ADC927" }}>
        {t("Dashboard")}
      </Link>
      {pathnames.map((name, index) => {
        breadcrumbPath += `/${name}`;
        const isLast = index === pathnames.length - 1;

        return (
          <span key={breadcrumbPath}>
            <span style={{ color: "#98a6ad" }}>/ </span>
            {isLast ? (
              <span
                style={{
                  color: "#999",
                }}
              >
                {t(name)}
              </span>
            ) : (
              <Link to={breadcrumbPath} style={{ color: "#ADC927" }}>
                {t(name)}
              </Link>
            )}
          </span>
        );
      })}
    </div>
  );
};

export default BreadCrumbs;
