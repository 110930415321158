import { createContext } from "react";

const authContext = createContext({
  authenticated: false,
  setAuthenticated: (auth) => {},
  languages: "en",
  setLanguages: (lang) => {},
});

export default authContext;
