import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import translationEN from "../../../src/component/languageChange/translationEN.json";
import translationDE from "../../../src/component/languageChange/translationDE.json";
import translationTM from "../../../src/component/languageChange/translationTM.json";
import translationSP from "../../../src/component/languageChange/translationSP.json";
import translationFR from "../../../src/component/languageChange/translationFR.json";
import translationHI from "../../../src/component/languageChange/translationHI.json";
import translationPR from "../../../src/component/languageChange/translationPR.json";
import translationRU from "../../../src/component/languageChange/translationRU.json";
import translationCH from "../../../src/component/languageChange/translationCH.json";

const resources = {
  en: {
    translation: translationEN,
  },
  ar: {
    translation: translationDE,
  },
  // ta: {
  //   translation: translationTM,
  // },
  tm: {
    translation: translationTM,
  },
  sp: {
    translation: translationSP,
  },
  fr: {
    translation: translationFR,
  },
  hi: {
    translation: translationHI,
  },
  pr: {
    translation: translationPR,
  },
  ru: {
    translation: translationRU,
  },
  ch: {
    translation: translationCH,
  },
};

i18n
  .use(Backend)

  .use(LanguageDetector)

  .use(initReactI18next)

  .init({
    resources,
    fallbackLng: "en",
    debug: true,
    lng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
