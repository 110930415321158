//const api_key = "8b9db89daa4111a468e0cc7dfde4e9dc";
//const student_id = 140;

import AppStrings from "../constants/AppStrings";

// const customer_id = 1005;
// const api_key = "ad2a1e417e40a46800bee2184faa833f";
// const student_id = 62;
// const is_logged_in = true;

const customer_id = JSON.parse(localStorage.getItem(AppStrings.CUSTOMER_ID));
const api_key = JSON.parse(localStorage.getItem(AppStrings.API_KEY));
const student_id = JSON.parse(localStorage.getItem(AppStrings.STUDENT_ID));
const user_id = JSON.parse(localStorage.getItem(AppStrings.USER_ID));
const is_logged_in = JSON.parse(localStorage.getItem("is_logged_in"));
const student_Profile = localStorage.getItem(AppStrings.STUDENT_PROFILEPICTURE);
const student_Name = localStorage.getItem(AppStrings.STUDENT_NAME);
const Params = {
  customer_id: customer_id,
  api_key: api_key,
  student_id: student_id,
  is_logged_in: is_logged_in,
  user_id: user_id,
  student_Profile: student_Profile,
  student_Name: student_Name,
};

export default Params;
