import React, { useEffect, useState, useRef } from "react";
import { FaPrint } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { simplePostCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Params from "../../config/Params";
import { toWords } from "number-to-words";
import BreadCrumbs from "../../component/BreadCrumbs";
import Footer from "../../component/Footer";
import "../../assets/new/css/Reciept.css";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const TransactionHis = () => {
  const { t } = useTranslation();

  const [recipetData, setRecipetData] = useState([]);
  const [eq, setEq] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalAmountInWords, setTotalAmountInWords] = useState("");
  const [paymentAmountInWords, setPaymentAmountInWords] = useState("");
  const receiptRef = useRef();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get("message");
  const transactions = params.get("type");

  const walletFilterss = () => {
    let RequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      transaction_id: id,
      transaction_type: transactions,
      language: i18next.language,
    });
    simplePostCall(ApiConfig.WALLET_DETAILS, RequestBody)
      .then((data) => {
        setRecipetData(data.data[0]);
        setEq(data.data[0].item_details);
      })
      .catch((error) => console.log(error, "error"));
  };

  useEffect(() => {
    walletFilterss();
  }, []);

  useEffect(() => {
    if (eq?.length > 0) {
      const total = eq.reduce(
        (sum, item) => sum + parseFloat(item.total_price),
        0
      );
      setTotalAmount(total.toFixed(2));
      setTotalAmountInWords(
        toWords(total).replace(/\b\w/g, (c) => c.toUpperCase())
      );
    }
  }, [eq]);

  useEffect(() => {
    if (recipetData?.payment_amount) {
      setPaymentAmountInWords(
        toWords(recipetData.payment_amount).replace(/\b\w/g, (c) =>
          c.toUpperCase()
        )
      );
    }
  }, [recipetData]);

  // const handlePrint = () => {
  //   window.print();
  // };
  const handlePrint = () => {
    const printContent = receiptRef.current;

    const printWindow = window.open("", "_blank", "height=500,width=800");

    // Add necessary styles for printing to the print window
    printWindow.document.write("<html><head><title>Print Receipt</title>");
    printWindow.document.write(
      "<style>@media print { body { margin: 0; padding: 0; font-family: Arial, sans-serif; } }</style>"
    );
    printWindow.document.write(
      "<style>" +
        `
        /* Style adjustments for print layout */
        body {
          margin: 0;
          padding: 20px;
        }
        .invoice-outer-wrappers {
          max-width: 800px;
          margin: 0 auto;
          padding: 20px;
          background-color: #f9f9f9;
        }
        .invoice-inner-wrapper {
          font-size: 14px;
        }
        .heading-table {
          margin-bottom: 30px;
         
        }
        .left-title {
          font-size: 18px;
          font-weight: bold;
          background-color: #d6e492;
          border-radius: 10px;
          padding: 10px; 
          display: flex;
          align-items: center;
          text-align: center;

        }
           .hidd{
            display: none;
           }
        .invoice-head {
          margin-bottom: 20px;
        ;
       
              
        }
  .invoice-head1{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap:10px;
  }
 .invoice-head2{
   display: flex;
   align-items: center;
   justify-content: space-between;
   gap:3px;
  }
        .address-row1 {
         display: flex;
        flex-direction: column;
        padding:20px;
        }
        .main-heading-reciept {
          font-weight: bold;
        }
        .main-sub-vaue {
          color: #555;
        }
        .particular-table {
          width: 100%;
          border-collapse: collapse;
          margin-bottom: 20px;
           border-color:rgb(255, 255, 255);
        }
        .particular-table th, .particular-table td {
          border: 1px solid #ddd;
          padding: 8px;
          text-align: left;
          border:none;
        }
        .particular-table th {
          // background-color: #f2f2f2;
          background-color: #d6e492 ;
        }
        .main-sub-vaue-bottom {
          font-size: 14px;
          font-style: italic;
        }
        .m-10 {
          margin-top: 10px;
        }
        .main-heading-reciept {
          font-size: 16px;
          font-weight: bold;
          color: #78266a;
       text-align: start;
        }
        .main-sub-vaue {
          font-size: 14px;
        }
      ` +
        "</style>"
    );
    printWindow.document.write(
      '<link rel="stylesheet" type="text/css" href="/path/to/your/Reciept.css" />'
    ); // Ensure the print styles are applied
    printWindow.document.write("</head><body>");
    printWindow.document.write(printContent.innerHTML);
    printWindow.document.write("</body></html>");

    printWindow.document.close();
    printWindow.print();
  };

  return (
    <>
      <div className="page-wrapper compact-wrapper" id="pageWrapper">
        <div className="page-body-wrapper sidebar-icon">
          <div className="page-body" id="container">
            <BreadCrumbs />
            <div className="Forum library">
              <div className="invoice-outer-wrappers" ref={receiptRef}>
                <div className="invoice-inner-wrapper">
                  <div className="heading-table">
                    <div>
                      <div
                        className="left-title "
                        style={{
                          display: "flex",

                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div
                          className="lt-heading"
                          style={{ textAlign: "left" }}
                        >
                          {t("Receipt")}
                        </div>
                        <div
                          style={{
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            marginRight: 20,
                            marginTop: 20,
                          }}
                          className="hidd"
                        >
                          <FaPrint
                            onClick={handlePrint}
                            style={{
                              cursor: "pointer",
                              position: "relative",
                              color: "#78266a",
                              right: "10px",
                            }}
                            className="hidd"
                          />
                          <span
                            className="hidd"
                            onClick={handlePrint}
                            style={{
                              cursor: "pointer",
                              color: "#78266a",
                              fontWeight: "bold",
                              right: "50px",
                            }}
                          >
                            {t("Print")}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="invoice-head m-15">
                    <div className="row  invoice-head2">
                      <div className="address-row  col-md-4">
                        <div className="row invoice-head1">
                          <p className="col main-heading-reciept">
                            {t("Payment Mode")}:
                          </p>
                          <p className="col main-sub-vaue">
                            {recipetData.name}
                          </p>
                        </div>
                        <div className="row invoice-head1">
                          <p className="col main-heading-reciept">
                            {transactions === "credit"
                              ? t("UPI Transaction No:")
                              : t("Bill No:")}
                          </p>
                          <p className="col main-sub-vaue">
                            {recipetData.payment_gateway_transaction_no ||
                              recipetData.payment_bill_no}
                          </p>
                        </div>
                        <div className="row invoice-head1">
                          <p className="col main-heading-reciept">
                            {transactions === "credit"
                              ? t("Credit Date:")
                              : t("Purchase Date:")}
                          </p>
                          <p className="col main-sub-vaue">
                            {" "}
                            {recipetData.payment_date}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4 address-row1"></div>
                      <div className="col-md-4 address-row1">
                        <div className="row invoice-head1">
                          <p className="col main-heading-reciept">
                            {" "}
                            {t("Receipt")}:
                          </p>
                          <p className="col main-sub-vaue">
                            {" "}
                            {transactions === "credit"
                              ? recipetData.payment_mode
                              : recipetData.operator_name}
                          </p>
                        </div>
                        <div className="row invoice-head1 ">
                          <p className="col main-heading-reciept">
                            {t("Transaction Ref No")}:
                          </p>
                          <p className="col main-sub-vaue">
                            {recipetData.payment_reference_no}
                          </p>
                        </div>
                        <div className="row invoice-head1">
                          <p className="col main-heading-reciept">
                            {transactions === "credit"
                              ? t("Credit Time:")
                              : t("Purchase Time:")}
                          </p>
                          <p className="col main-sub-vaue">
                            {" "}
                            {recipetData.payment_time}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {transactions !== "credit" && (
                    <div
                      className="table-res 
                    "
                    >
                      <table className="particular-table">
                        <thead>
                          <tr>
                            <th>Sr.No</th>
                            <th>{t("Item")}</th>
                            <th>{t("Quantity")}</th>
                            <th>{t("Price")}</th>
                            <th>{t("Total Price")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {eq?.map((item, index) => (
                            <tr key={index}>
                              <td className="main-sub-vaue">{index + 1}</td>
                              <td className="main-sub-vaue">
                                {item.product_name}
                              </td>
                              <td className="main-sub-vaue">{item.quantity}</td>
                              <td className="main-sub-vaue">{item.price}</td>
                              <td className="main-sub-vaue">
                                {item.total_price}
                              </td>
                            </tr>
                          ))}
                          <tr>
                            <td colSpan={3}></td>
                            <td className="main-heading-reciept">
                              {t("Total Amount Paid")}
                            </td>
                            <td className="main-heading-reciept">
                              {transactions === "credit"
                                ? recipetData.payment_amount
                                : totalAmount}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                  <div>
                    <p className="main-sub-vaue-bottom">
                      {t("Payment Received")}{" "}
                      {transactions === "credit"
                        ? paymentAmountInWords
                        : totalAmountInWords}{" "}
                      {t("USD Only")}
                    </p>
                    <div className="m-10 main-heading-reciept">
                      {t("Thank You")}.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default TransactionHis;
